import { Auth } from "aws-amplify"

// this function allows API (and graphQL API) access to
// extended user identity information via authentication header
const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});

export const apiconfig = {API:
    {endpoints:
    [{
        name: 'apwbench-pub',
        // endpoint: 'https://api.wbench.wernerdigital.com/core',
        endpoint: 'https://api.wbench.wernerdigital.com/core-test',
    },
    {
        name: 'apwbench',
        // endpoint: 'https://api.wbench.wernerdigital.com/core',
        endpoint: 'https://api.wbench.wernerdigital.com/core-test',
        custom_header: getIdToken,
    }],
    }
}

export const storageconfig = {
    Storage: {
        bucket: "file-locker",
        region: "us-east-1",
    },
}

const awsmobile = {
    // set identity pool and cognito to wbench dev
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:269d1938-b9f1-4dc4-aa93-dda857b50ffe",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xsGv3mn6H",
    "aws_user_pools_web_client_id": "1b12vpqv9n3qkjp7orgpheege4",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],

    // This section overrides the amplify prod/dev selection, and should be set appropriately in git
    // appsync - uncomment for prod versions
    // "aws_appsync_graphqlEndpoint": "https://rrnef36dzjc2ri2bymuc7lhwvu.appsync-api.us-east-1.amazonaws.com/graphql",
    // appsync wbenchTest - uncomment for test versions
    "aws_appsync_graphqlEndpoint": "https://rwikl2suhjgxbhnknpmsk7w3i4.appsync-api.us-east-1.amazonaws.com/graphql",
    // prod and  test settings
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "null",
    "graphql_headers": getIdToken,
};

export default awsmobile;